const inputs = document.querySelectorAll(".input");


function addcl(){
	let parent = this.parentNode.parentNode;
	parent.classList.add("focus");
}

function remcl(){
	let parent = this.parentNode.parentNode;
	if(this.value == ""){
		parent.classList.remove("focus");
	}
}


inputs.forEach(input => {
	input.addEventListener("focus", addcl);
	input.addEventListener("blur", remcl);
});

"use strict";

var indicator = document.querySelector('.nav-indicator');
var items = document.querySelectorAll('.nav-item');

function handleIndicator(el) {
	items.forEach(function (item) {
		item.classList.remove('is-active');
		item.removeAttribute('style');
	});
	indicator.style.width = "".concat(el.offsetWidth, "px");
	indicator.style.left = "".concat(el.offsetLeft, "px");
	indicator.style.backgroundColor = el.getAttribute('active-color');
	el.classList.add('is-active');
	el.style.color = el.getAttribute('active-color');
}

items.forEach(function (item, index) {
	item.addEventListener('click', function (e) {
		handleIndicator(e.target);
	});
	item.classList.contains('is-active') && handleIndicator(item);
});

